import React from "react";

function HeartIcon( {className} ) {
  return (
    <svg
      width="120"
      height="113"
      viewBox="0 0 120 113"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M117.097 23.0965C115.235 18.5768 112.55 14.4811 109.193 11.0386C105.833 7.58589 101.872 4.84206 97.5251 2.95633C93.0173 0.993176 88.1824 -0.0116762 83.3012 0.000102359C76.4532 0.000102359 69.7719 1.96606 63.9657 5.67955C62.5766 6.56787 61.257 7.54357 60.0069 8.60665C58.7567 7.54357 57.4371 6.56787 56.0481 5.67955C50.2419 1.96606 43.5605 0.000102359 36.7125 0.000102359C31.7814 0.000102359 27.0031 0.990365 22.4887 2.95633C18.1271 4.84947 14.1961 7.57269 10.8207 11.0386C7.45914 14.4772 4.77385 18.5739 2.917 23.0965C0.986224 27.8003 0 32.7952 0 37.9359C0 42.7852 0.944553 47.8385 2.81977 52.9791C4.38939 57.2751 6.63965 61.7313 9.51498 66.2312C14.0711 73.3523 20.3357 80.7793 28.1143 88.3082C41.0047 100.788 53.77 109.41 54.3118 109.759L57.6038 111.973C59.0623 112.948 60.9375 112.948 62.396 111.973L65.6881 109.759C66.2298 109.395 78.9813 100.788 91.8855 88.3082C99.6642 80.7793 105.929 73.3523 110.485 66.2312C113.36 61.7313 115.624 57.2751 117.18 52.9791C119.055 47.8385 120 42.7852 120 37.9359C120.014 32.7952 119.028 27.8003 117.097 23.0965Z"
      />
    </svg>
  );
}

export default HeartIcon;
