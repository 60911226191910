import React from "react";

function LogoutIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-[0.5]"
    >
      <path
        d="M7 6H23V15.8H25V6C25 5.46957 24.7893 4.96086 24.4142 4.58579C24.0391 4.21071 23.5304 4 23 4H7C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6V30C5 30.5304 5.21071 31.0391 5.58579 31.4142C5.96086 31.7893 6.46957 32 7 32H23C23.5304 32 24.0391 31.7893 24.4142 31.4142C24.7893 31.0391 25 30.5304 25 30H7V6Z"
        fill="black"
      />
      <path
        d="M28.1599 17.2795C27.9686 17.1157 27.7225 17.0301 27.4708 17.0398C27.2192 17.0495 26.9804 17.1539 26.8023 17.332C26.6242 17.5101 26.5199 17.7488 26.5102 18.0005C26.5004 18.2521 26.5861 18.4982 26.7499 18.6895L30.1299 21.9995H15.6299C15.3647 21.9995 15.1103 22.1049 14.9228 22.2924C14.7352 22.4799 14.6299 22.7343 14.6299 22.9995C14.6299 23.2647 14.7352 23.5191 14.9228 23.7066C15.1103 23.8942 15.3647 23.9995 15.6299 23.9995H30.1299L26.7499 27.4595C26.6452 27.5492 26.5602 27.6595 26.5002 27.7835C26.4401 27.9076 26.4064 28.0427 26.4011 28.1805C26.3958 28.3182 26.419 28.4555 26.4692 28.5838C26.5195 28.7122 26.5958 28.8287 26.6932 28.9262C26.7907 29.0236 26.9072 29.0999 27.0356 29.1502C27.1639 29.2004 27.3012 29.2236 27.4389 29.2183C27.5767 29.213 27.7118 29.1793 27.8359 29.1192C27.9599 29.0592 28.0702 28.9742 28.1599 28.8695L33.9999 23.0695L28.1599 17.2795Z"
        fill="black"
      />
    </svg>
  );
}

export default LogoutIcon;
