import React from "react";

// Logo SVG Icon
function LogoIcon( {className} ) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.26 76V27.4H31.82C35.22 27.4 37.86 28 39.74 29.2C41.66 30.36 43.02 32.02 43.82 34.18C44.62 36.34 45.02 38.84 45.02 41.68V61C45.02 64 44.62 66.64 43.82 68.92C43.02 71.16 41.7 72.9 39.86 74.14C38.02 75.38 35.48 76 32.24 76H21.26ZM25.82 72.58H31.94C34.5 72.58 36.38 72.06 37.58 71.02C38.78 69.94 39.54 68.48 39.86 66.64C40.18 64.76 40.34 62.64 40.34 60.28V42.16C40.34 39.72 40.12 37.68 39.68 36.04C39.28 34.36 38.46 33.1 37.22 32.26C36.02 31.38 34.2 30.94 31.76 30.94H25.82V72.58ZM52.4891 76V27.4H57.1091V48.94H72.4091V27.4H76.9691V76H72.4091V52.36H57.1091V76H52.4891Z"
        fill="black"
      />
      <circle cx="50" cy="50" r="49.5" stroke="black" />
    </svg>
  );
}

export default LogoIcon;
