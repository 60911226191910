// Handles base config of axios
import axios from "axios";

const api = axios.create({
  baseURL: "https://desperate-housewares.herokuapp.com/api/v1",
  withCredentials: true,
  // 'Access-Control-Allow-Origin': 'https://desperate-housewares.herokuapp.com'
});

export default api;
