import React from "react";

function BagIcon() {
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7C19 5.67392 18.4732 4.40215 17.5355 3.46447C16.5979 2.52678 15.3261 2 14 2C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V8H19V7ZM21 7V8H28V28C28 29.0609 27.5786 30.0783 26.8284 30.8284C26.0783 31.5786 25.0609 32 24 32H4C2.93913 32 1.92172 31.5786 1.17157 30.8284C0.421427 30.0783 0 29.0609 0 28V8H7V7C7 5.14348 7.7375 3.36301 9.05025 2.05025C10.363 0.737498 12.1435 0 14 0C15.8565 0 17.637 0.737498 18.9497 2.05025C20.2625 3.36301 21 5.14348 21 7ZM26 28V10H2V28C2 28.5304 2.21071 29.0391 2.58579 29.4142C2.96086 29.7893 3.46957 30 4 30H24C24.5304 30 25.0391 29.7893 25.4142 29.4142C25.7893 29.0391 26 28.5304 26 28ZM14 15.986C17.328 12.564 25.65 18.552 14 26.25C2.35 18.55 10.672 12.564 14 15.986Z"
        fill="black"
      />
    </svg>
  );
}

export default BagIcon;
