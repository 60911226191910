import React from "react";

function DollarSignIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=""
    >
      <path
        d="M0 9.781C0.148 11.448 1.513 12.631 3.591 12.784V14H4.634V12.784C6.904 12.605 8.312 11.346 8.312 9.484C8.312 7.894 7.365 6.974 5.356 6.456L4.634 6.269V2.467C5.756 2.577 6.513 3.181 6.704 4.083H8.174C8.008 2.483 6.634 1.335 4.634 1.208V0H3.591V1.233C1.652 1.463 0.321 2.705 0.321 4.389C0.321 5.843 1.287 6.872 2.982 7.306L3.592 7.468V11.499C2.443 11.329 1.652 10.699 1.461 9.781H0V9.781ZM3.391 5.945C2.348 5.682 1.791 5.12 1.791 4.329C1.791 3.385 2.495 2.688 3.591 2.501V5.996L3.391 5.946V5.945ZM4.982 7.817C6.269 8.14 6.834 8.676 6.834 9.586C6.834 10.683 6.008 11.414 4.634 11.525V7.73L4.982 7.816V7.817Z"
        fill="black"
      />
    </svg>
  );
}

export default DollarSignIcon;
