import React from "react";

// Login and logout SVG icon
function LoginIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 4H12C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6H28V30H12V20.2H10V30C10 30.5304 10.2107 31.0391 10.5858 31.4142C10.9609 31.7893 11.4696 32 12 32H28C28.5304 32 29.0391 31.7893 29.4142 31.4142C29.7893 31.0391 30 30.5304 30 30V6C30 5.46957 29.7893 4.96086 29.4142 4.58579C29.0391 4.21071 28.5304 4 28 4V4Z"
        fill="black"
      />
      <path
        d="M15.12 18.4603C15.0153 18.5499 14.9303 18.6602 14.8703 18.7843C14.8103 18.9084 14.7765 19.0435 14.7712 19.1812C14.7659 19.3189 14.7891 19.4563 14.8394 19.5846C14.8896 19.7129 14.9659 19.8295 15.0633 19.9269C15.1608 20.0244 15.2774 20.1006 15.4057 20.1509C15.534 20.2012 15.6713 20.2244 15.8091 20.2191C15.9468 20.2137 16.0819 20.18 16.206 20.12C16.33 20.06 16.4404 19.9749 16.53 19.8703L22.32 14.0803L16.54 8.29026C16.3487 8.12643 16.1026 8.04083 15.8509 8.05055C15.5993 8.06027 15.3605 8.1646 15.1824 8.3427C15.0043 8.52079 14.9 8.75953 14.8903 9.01121C14.8806 9.26288 14.9662 9.50896 15.13 9.70026L18.5 13.0003H4C3.73478 13.0003 3.48043 13.1056 3.29289 13.2932C3.10536 13.4807 3 13.735 3 14.0003C3 14.2655 3.10536 14.5198 3.29289 14.7074C3.48043 14.8949 3.73478 15.0003 4 15.0003H18.5L15.12 18.4603Z"
        fill="black"
      />
    </svg>
  );
}

export default LoginIcon;
