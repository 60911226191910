import React from "react";

function AddListingIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-1"
    >
      <path
        d="M26 26H6V6H16V4H6C5.46973 4.00053 4.96133 4.21141 4.58637 4.58637C4.21141 4.96133 4.00053 5.46973 4 6V26C4.00053 26.5303 4.21141 27.0387 4.58637 27.4136C4.96133 27.7886 5.46973 27.9995 6 28H26C26.5303 27.9995 27.0387 27.7886 27.4136 27.4136C27.7886 27.0387 27.9995 26.5303 28 26V16H26V26Z"
        fill="black"
      />
      <path
        d="M26 26H6V6H16V4H6C5.46973 4.00053 4.96133 4.21141 4.58637 4.58637C4.21141 4.96133 4.00053 5.46973 4 6V26C4.00053 26.5303 4.21141 27.0387 4.58637 27.4136C4.96133 27.7886 5.46973 27.9995 6 28H26C26.5303 27.9995 27.0387 27.7886 27.4136 27.4136C27.7886 27.0387 27.9995 26.5303 28 26V16H26V26Z"
        fill="black"
      />
      <path d="M26 6V2H24V6H20V8H24V12H26V8H30V6H26Z" fill="black" />
    </svg>
  );
}

export default AddListingIcon;
